import React from "react"
import Headertop from "../components/headertop"
import Header from "../components/header"
import Footer from "../components/footer"
import Containers from "../components/container"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Seotitle = "Page Not Found"
const seoDescription = "Oop! The Page you are looking for has been removed or relocated"

export default function Page404() {
  return (
        <Containers>
            <Seo title={Seotitle} description={seoDescription} />
            <Headertop />
            <Header />
                <Container fluid="xl" style={{paddingTop: "10%", paddingBottom:"10%"}}>
                    <Row>
                    <Col md={12}>
                        <h1>Page Not Found</h1>
                        <p>Oop! The Page you are looking for has been removed or relocated</p>
                        <Link to="/">Go Back</Link>
                    </Col>
                    </Row>
                </Container>
        <Footer />
        </Containers>
    );
}